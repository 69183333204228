export const menu = [
  {
    title: "Home",
    to: "home",
    state: "active",
    name: "nav-link",
  },
  {
    title: "About",
    to: "about",
    state: "active",
    name: "nav-link",
  },
  {
    title: "Resume",
    to: "resume",
    state: "active",
    name: "nav-link",
  },
  {
    title: "Portfolio",
    to: "portfolio",
    state: "active",
    name: "nav-link",
  },
  {
    title: "Contact",
    to: "contact",
    state: "active",
    name: "nav-link",
  },
];
